import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { PageLoader } from './Loader'
import * as productActions from '../store/actions/products'
import '../styles/forms.css'

const fetcher = (url) => fetch(url).then((res) => res.json())
const secretPassword = process.env.REACT_APP_PASSWORD

const EditProductForm = (props) => {
    const navigate = useNavigate()
    const { id } = useParams()

    const { updateProduct, addProduct } = props

    const [name, setName] = useState('')
    const [image, setImage] = useState('')
    const [category, setCategory] = useState('')
    const [link, setLink] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')

    const url = `https://ihatecars.net/php/getSingleProduct.php?id=${id}`

    const { data, error, isLoading } = useSWR(url, fetcher)

    useEffect(() => {
        if (data) {
            setName(data.name)
            setImage(data.image)
            setCategory(data.category)
            setLink(data.link)
            setPrice(data.price)
            setDescription(data.description)
        }
    }, [data])

    if (error)
        return (
            <div>
                <h1>Error.</h1>
                <p>Please refresh the page.</p>
            </div>
        )
    if (isLoading) return <PageLoader />

    const loggedIn = sessionStorage.getItem('password')
    if (secretPassword !== loggedIn) return (
        <div>
            <h1>Error.</h1>
            <p>You are not authorized to view this page.</p>
        </div>
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            name,
            image,
            category,
            link,
            price,
            description,
        }

        id === 'null'
            ? addProduct(data)
            : updateProduct({ ...data, id: id })

        navigate('/admin')
    }

    const goBack = () => {
        navigate('/admin/ep')
    }

    return (
        (
            <div className="container">
                <h1>{data ? 'Edit' : 'Add'} Product.</h1>

                <form className="main">
                    <div className="inputGroup">
                        <label htmlFor="name">Name of Product</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            className="inputField"
                            required
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="image">Image URL</label>
                        <input
                            type="text"
                            name="image"
                            value={image}
                            className="inputField"
                            required
                            onChange={(e) => {
                                setImage(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="category">Category</label>
                        <input
                            type="text"
                            name="category"
                            value={category}
                            className="inputField"
                            onChange={(e) => {
                                setCategory(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="link">Link URL</label>
                        <input
                            type="text"
                            name="link"
                            value={link}
                            className="inputField"
                            onChange={(e) => {
                                setLink(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="price">Price</label>
                        <input
                            type="text"
                            name="price"
                            value={price}
                            className="inputField"
                            onChange={(e) => {
                                setPrice(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="description">Description</label>
                        <textarea
                            name="description"
                            value={description}
                            className="smtextarea"
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }}
                        />
                    </div>

                    <div className="button_container">
                        <input
                            type="submit"
                            value="Submit"
                            className="submit"
                            onClick={handleSubmit}
                        />
                        <button onClick={goBack}>Cancel</button>
                    </div>
                </form>
            </div>
        )
    )
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProduct: (product) => dispatch(productActions.updateProduct(product)),
        addProduct: (product) => dispatch(productActions.addProduct(product))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProductForm)
