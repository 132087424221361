import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { isMobile } from 'react-device-detect'
import { PageLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/About.css'
import { addApos } from '../utils/regexInput'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://ihatecars.net/php/getPages.php'

// let count = 0
const About = (props) => {
  //   console.log(count++);

  const { data, error, isLoading } = useSWR(url, fetcher)

  const [pages, setPages] = useState(null)
  const [text, setText] = useState('')
  const [text2, setText2] = useState('')

  useEffect(() => {
    data && setPages(data[0])
  }, [data])

  useEffect(() => {
    if (pages && pages.about) {
      setText(pages.about)
    }
    if (pages && pages.about2) {
      setText2(pages.about2)
    }
  }, [pages])

  if (error) return <div><h1>Error.</h1><p>{error}</p></div>
  if (isLoading) return <PageLoader />

  return pages && (
    <div className='about'>
      <MetaDecorator
        title="I HATE CARS | About Jeff"
        description="Why is Jeff qualified to answer your questions? Why a podcast about cars?"
        url="https://ihatecars.net/about"
      />

      <h1>About Jeff.</h1>

      <div>
        {pages && pages.aboutImage && (
          <img
            src={pages.aboutImage}
            height={isMobile ? '' : 300}
            width={isMobile ? '100%' : ''}
            className="about_image"
            alt="Jeff Rickermann"
          />
        )}

        {text.includes('<') ? (
          <div
            dangerouslySetInnerHTML={{
              __html: addApos(text),
            }}
          ></div>
        ) : (
          <p>
            {text.length
              ? addApos(text)
              : 'Why are you qualified to answer my questions?'}
          </p>
        )}

        <br />

        {pages && pages.aboutImage2 && (
          <img
            src={pages.aboutImage2}
            height={isMobile ? '' : 300}
            width={isMobile ? '100%' : ''}
            className="about_image_right"
            alt="Jeff Rickermann"
          />
        )}

        {text2.includes('<') ? (
          <div
            className='secondParagraph'
            dangerouslySetInnerHTML={{
              __html: addApos(text2),
            }}
          ></div>
        ) : (
          <p>
            {text2.length
              ? addApos(text2)
              : 'Why a podcast?'}
          </p>
        )}
      </div>

      <div className='logoContainer'>
        <img src='https://ihatecars.net/logo512.png' alt='I HATE CARS' className='logo' />
      </div>
    </div>
  )
}

export default About
