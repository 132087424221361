import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Podcasts.css'

const fetcher = (url) => fetch(url).then((res) => res.json())

// to embed on website,
// go to https://podcasters.spotify.com/pod/show/jeff-rickermann/episodes/Why-should-you-listen-to-Jeff-Rickermann-and-the-I-HATE-CARS-podcast-e29s5na
// and click on episode and click share to get src from embed code.

const Podcasts = () => {

  const url = `https://ihatecars.net/php/getPodcasts.php`

  const { data, error, isLoading } = useSWR(url, fetcher)

  console.log(data)

  const renderItem = (item) => {
    console.log(item)
    return (
      <div style={styles.iframeContainer} key={item.id}>
        <iframe
          title={item.title}
          style={styles.previousIframe}
          src={item.url}
          width="92%"
          height="100"
          aria-label='podcast episode'
          frameBorder="0"
          allow="fullscreen;"
          loading="lazy"
          scrolling='no'></iframe>
      </div>
    )
  }

  if (error)
    return (
      <div>
        <h1>Error.</h1>
        <p>Please refresh the page.</p>
      </div>
    )
  if (isLoading || !data) return <PageLoader />


  return (
    <div style={styles.main}>
      <MetaDecorator
        title="I HATE CARS | Podcasts"
        description="Check out all the podcasts. Click on an item to see more information and reels."
        url="https://ihatecars.net/podcasts"
      />

      <h1>Latest Podcast.</h1>

      <iframe
        title='I HATE CARS'
        style={styles.showIframe}
        src="https://open.spotify.com/embed/show/6hKnqmoS1CzYzSLg6DH2IP?utm_source=generator&theme=0"
        width="100%"
        height="152"
        frameBorder="0"
        allow="fullscreen;"
        loading="lazy"></iframe>

      <h2 style={styles.previousEpisodes}>All Episodes.</h2>

      {data && data.map(i => renderItem(i))}
    </div>
  )
}

const styles = {
  main: {
    paddingBottom: '1rem',
  },
  showIframe: {
    borderRadius: 12,
    marginTop: 10,
    boxShadow: 'rgba(50, 50, 93, 0.5) 0px 6px 12px -2px, rgba(0, 0, 0, 0.5) 0px 3px 7px -3px',
  },
  iframeContainer: {
    borderRadius: 12,
    marginTop: 10,
    height: 152,
    width: '100%',
    backgroundColor: '#282828',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(50, 50, 93, 0.5) 0px 6px 12px -2px, rgba(0, 0, 0, 0.5) 0px 3px 7px -3px',
  },
  previousIframe: {
    borderRadius: 5,
  },
  previousEpisodes: {
    marginTop: '2.5rem',
    fontSize: '2.635rem',
  },
}

export default Podcasts
