import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ImagePickerHomePage from './ImagePickerHomePage'
import * as pagesActions from '../../store/actions/pages'
import '../../styles/forms.css'
import { removeApos, addApos } from '../../utils/regexInput'

const EditHome = (props) => {
  const { data, setPage, savePagesToDb } = props

  const [home, setHome] = useState('')
  const [homeImages, setHomeImages] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [allData, setAllData] = useState([])

  useEffect(() => {
    // set pages to first element in data array
    data && setAllData(data[0])
  }, [data])

  useEffect(() => {
    if (allData) {
      setHome(addApos(allData.home))
      setHomeImages(allData.homeImages ? allData.homeImages : [])
    }
  }, [allData])

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(false)
    savePagesToDb({
      ...data[0],
      home: removeApos(home),
      homeImages,
    })
    setSubmitted(true)
  }

  const handleContinue = () => {
    setSubmitted(false)
  }

  const goBack = () => {
    setPage('')
  }

  return submitted ? (
    <div>
      <h3 style={{ color: 'lime' }}>Submitted!</h3>
      <button onClick={handleContinue}>Continue Editing</button>
    </div>
  ) : (
    <div className="container">
      <h4 className="edit_pages_form_subtitle">Edit the Home Page.</h4>

      <form className="main">

        <div className="inputGroup">
          <label htmlFor="home">Home Page Text</label>
          <textarea
            name="home"
            value={home}
            className="textarea"
            onChange={(e) => {
              setHome(e.target.value)
            }}
          />
        </div>

        <div className="inputGroupPicker">
          <ImagePickerHomePage
            homeImages={homeImages}
            setHomeImages={setHomeImages}
            name="homeImages"
          />
        </div>

        <div className="button_container">
          <button onClick={goBack}>CANCEL</button>

          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePagesToDb: (data) => dispatch(pagesActions.savePagesToDb(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditHome)
