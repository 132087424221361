import { GET_PRODUCTS, GET_SINGLE_PRODUCT } from '../actions/products'

const initialState = {
    products: [],
    singleProduct: {}
}

const productsReducer = (state = initialState, action) => {
    if (action.type === GET_PRODUCTS) {
        return {
            ...state,
            products: action.products
        }
    } else if (action.type === GET_SINGLE_PRODUCT) {
        return {
            ...state,
            singleProduct: action.singleProduct
        }
    } else {
        return state
    }
}

export default productsReducer
