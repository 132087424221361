import { FaXTwitter, FaInstagram } from 'react-icons/fa6'
import { PiSpotifyLogo, PiApplePodcastsLogoDuotone } from 'react-icons/pi'
import { AiOutlineFacebook, AiOutlineYoutube, AiOutlineAmazon } from 'react-icons/ai'

import {
    // SiCastbox,
    // SiGooglepodcasts,
    SiIheartradio,
    // SiOvercast,
    // SiPocketcasts,
    // SiRadiopublic
} from 'react-icons/si'
import '../styles/SocialMediaButtons.css'
// import FeedButtons from '../../unused/FeedButtons'

const twitter = 'https://twitter.com/jeffhatescars'
const instagram = 'https://www.instagram.com/jeffhatescars/'
const youtube = 'https://www.youtube.com/@jeffhatescars'
const facebook = 'https://www.facebook.com/jeffhatescars/'

const spotify = 'https://open.spotify.com/show/6hKnqmoS1CzYzSLg6DH2IP'
const amazonMusic = 'https://music.amazon.com/podcasts/13ecd2e1-c4fa-4d9e-9718-6ccdf01820a1/i-hate-cars'
const applePodcasts = 'https://podcasts.apple.com/us/podcast/i-hate-cars/id1709523888'
// const castbox = ''
// const googlePodcasts = ''
const iHeartRadio = 'https://iheart.com/podcast/124408344/'
// const overcast = 'https://overcast.fm/itunes1709523888'
// const pocketCasts = 'https://pca.st/t8syw0fk'
// const radioPublic = 'https://radiopublic.com/i-hate-cars-G32zQv'

const SocialMediaButtons = ({ footer = false }) => {
    return (
        <div className='smb'>
            {/* PODCAST CHANNELS  */}

            <a href={spotify} rel="noreferrer" target='_blank' className='smb_link'>
                <PiSpotifyLogo size={28} color='red' title='Spotify' />
            </a>

            <a href={amazonMusic} rel="noreferrer" target='_blank' className='smb_link'>
                <AiOutlineAmazon size={26} color='red' title='Amazon Music' />
            </a>

            <a href={applePodcasts} rel="noreferrer" target='_blank' className='smb_link'>
                <PiApplePodcastsLogoDuotone size={25} color='red' title='Apple Podcasts' />
            </a>

            {/* <a href={castbox} rel="noreferrer" target='_blank' className='smb_link'>
                <SiCastbox size={25} color='red' title='Castbox' />
            </a> */}

            {/* <a href={googlePodcasts} rel="noreferrer" target='_blank' className='smb_link'>
                <SiGooglepodcasts size={25} color='red' title='Google Podcasts' />
            </a> */}

            <a href={iHeartRadio} rel="noreferrer" target='_blank' className='smb_link'>
                <SiIheartradio size={25} color='red' title='iHeartRadio' />
            </a>

            {/*
            <a href={overcast} rel="noreferrer" target='_blank' className='smb_link'>
                <SiOvercast size={25} color='red' title='Overcast' />
            </a> */}

            {/* <a href={pocketCasts} rel="noreferrer" target='_blank' className='smb_link'>
                <SiPocketcasts size={25} color='red' title='Pocket Casts' />
            </a> */}

            {/* <a href={radioPublic} rel="noreferrer" target='_blank' className='smb_link'>
            {/* <a href={radioPublic} rel="noreferrer" target='_blank' className='smb_link'>
                <SiRadiopublic size={25} color='red' title='RadioPublic' />
            </a> */}
            {/* </a> * /} */}

            {/* SOCIAL MEDIA */}

            <a href={twitter} rel="noreferrer" target='_blank' className='smb_link'>
                <FaXTwitter size={25} color='red' title='Twitter' />
            </a>

            <a href={instagram} rel="noreferrer" target='_blank' className='smb_link'>
                <FaInstagram size={25} color='red' title='Instagram' />
            </a>

            <a href={facebook} rel="noreferrer" target='_blank' className='smb_link'>
                <AiOutlineFacebook size={27} color='red' title='Facebook' />
            </a>

            <a href={youtube} rel="noreferrer" target='_blank' className='smb_link'>
                <AiOutlineYoutube size={34} color='red' className='youtube' title='YouTube' />
            </a>

            {/* <FeedButtons /> */}

        </div >
    )
}

export default SocialMediaButtons