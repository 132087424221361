import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ImagePicker from './ImagePicker'
import * as pagesActions from '../../store/actions/pages'
import '../../styles/forms.css'
import { removeApos, addApos } from '../../utils/regexInput'

const EditAbout = (props) => {
  const { data, setPage, savePagesToDb } = props

  const [about, setAbout] = useState('')
  const [about2, setAbout2] = useState('')
  const [aboutImage, setAboutImage] = useState('')
  const [aboutImage2, setAboutImage2] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [allData, setAllData] = useState([])

  useEffect(() => {
    data && setAllData(data[0])
  }, [data])

  useEffect(() => {
    if (allData) {
      setAbout(addApos(allData.about))
      setAbout2(addApos(allData.about2))
      setAboutImage(allData.aboutImage)
      setAboutImage2(allData.aboutImage2)
    }
  }, [allData])

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(false)
    savePagesToDb({
      ...data[0],
      about: removeApos(about),
      about2: removeApos(about2),
      aboutImage,
      aboutImage2,
    })
    setSubmitted(true)
  }

  const handleContinue = () => {
    setSubmitted(false)
  }

  const goBack = () => {
    setPage('')
  }

  return submitted ? (
    <div>
      <h3 style={{ color: 'lime' }}>Submitted!</h3>
      <button onClick={handleContinue}>Continue Editing</button>
    </div>
  ) : (
    <div className="container">
      <h4 className="edit_pages_form_subtitle">Edit the About page.</h4>

      <form className="main">

        <div className="inputGroupPicker">
          <label htmlFor="aboutImage">First Image for About Page</label>
          <ImagePicker
            image={aboutImage}
            setImage={setAboutImage}
            name="aboutImage"
          />
        </div>

        <div className="inputGroup">
          <label htmlFor="about">First Paragraph for About Page</label>
          <textarea
            name="about"
            value={about}
            className="textarea"
            onChange={(e) => {
              setAbout(e.target.value)
            }}
          />
        </div>

        <div style={{ height: 1, margin: '50px 20px' }}></div>

        <div className="inputGroupPicker">
          <label htmlFor="aboutImage2">Second Image for About Page</label>
          <ImagePicker
            image={aboutImage2}
            setImage={setAboutImage2}
            name="aboutImage2"
          />
        </div>

        <div className="inputGroup">
          <label htmlFor="about2">Second Paragraph for About Page</label>
          <textarea
            name="about2"
            value={about2}
            className="textarea"
            onChange={(e) => {
              setAbout2(e.target.value)
            }}
          />
        </div>

        <div className="button_container">
          <button onClick={goBack}>CANCEL</button>

          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePagesToDb: (data) => dispatch(pagesActions.savePagesToDb(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAbout)
