import { GET_PAGES } from '../actions/pages';

const initialState = {
  pages: {},
};

const pagesReducer = (state = initialState, action) => {
  if (action.type === GET_PAGES) {
    return {
      pages: action.pages,
    };
  } else {
    return state;
  }
};

export default pagesReducer;
