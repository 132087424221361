import { useState, useEffect } from 'react'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import ProgressBar from '@ramonak/react-progress-bar'
import { StyledDropZone } from 'react-drop-zone'
import '../../styles/dropzone.css'
import { SectionLoader } from '../Loader'
import ImageDisplayer from './ImageDisplayer'

const ImagePickerHomePage = (props) => {
  const { homeImages, setHomeImages, setSubmitted = () => { } } = props

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [isError, setIsError] = useState(false)
  const [progress, setProgress] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [state, setState] = useState({
    name: null,
    file: null,
  })

  useEffect(() => {
    homeImages.length === 0 && setMessage('')
  }, [homeImages])

  const onChangeHandler = async (file) => {
    if (isLoading || showProgress) return
    setSubmitted(false)
    setMessage('')
    setIsError(false)
    setIsLoading(true)

    if (!file.type.includes('image')) {
      setMessage(
        `Error! You must choose an image file.\nYour file type is ${file.type ? file.type : 'unknown'
        }`
      )
      setIsError(true)
    } else if (file.size > 25000000) {
      setMessage(
        `Error! This file is too large!\nMaximum size is 25M\nYour file is ${(
          file.size / 1000000
        ).toFixed(3)}M`
      )
      setIsError(true)
    } else {
      setState({ name: file.name, file: file })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    state.file && uploadHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.file])

  const uploadHandler = async () => {
    if (state.file === '' || !state.file) return

    setMessage(`Uploading file...`)
    setIsError(false)
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', state.file)

    const url = 'https://ihatecars.net/php/uploadImage.php'

    await axios
      .post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const completed = (progressEvent.progress * 100).toFixed(0)
          setShowProgress(true)
          setProgress(completed)
          setMessage(`Please wait...`)
        },
      })
      .then((res) => {
        setMessage(`${res.data.status} ${res.data.message}`)
        setIsError(res.data.error)
        homeImages === ''
          ? setHomeImages(res.data.url)
          : setHomeImages(homeImages + ',' + res.data.url)
      })
      .then(() => {
        setShowProgress(false)
      })
      .catch((e) =>
        setMessage(`${e.message}\nWe're working to fix this right now!`)
      )
    setIsLoading(false)
  }

  return (
    <div style={styles.picker}>
      <p style={styles.heading}>Images for Home Page</p>

      <div style={styles.marginBottom}>
        <ImageDisplayer imageArray={homeImages} setImageArray={setHomeImages} />
      </div>

      <StyledDropZone
        onDrop={(file, p) => {
          onChangeHandler(file)
        }}
        accept="image/*"
      />

      <div style={styles.message}>
        {showProgress && <ProgressBar completed={progress} bgColor="red" />}

        {isLoading && !showProgress && <SectionLoader />}

        <p style={isError ? styles.error : styles.message}>{message}</p>
      </div>
    </div>
  )
}

const styles = {
  picker: {
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: 20,
  },
  message: {
    fontWeight: 'bold',
    pAlign: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  error: {
    fontWeight: 'bold',
    pAlign: 'center',
    color: 'red',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 10,
  },
  noVideo: {
    width: isMobile ? 300 : 400,
    height: 100,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#ccc',
    borderWidth: 1,
    backgroundColor: '#ccc',
    maxWidth: '100%',
    marginHorizontal: 'auto',
  },
  loader: {
    marginRight: 8,
  },
  hide: {
    display: 'none',
  },
  filename: {
    marginBottom: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  heading: {
    marginBottom: 40,
  },
}

export default ImagePickerHomePage
