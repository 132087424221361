import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Shop from './pages/Shop'
import Contact from './pages/Contact'
import Podcasts from './pages/Podcasts'
import Layout from './components/Layout'
import Admin from './pages/Admin'
import EditProductForm from './components/EditProductForm'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers'

const store = createStore(rootReducer, compose(applyMiddleware(thunk)))

function App() {
  return (
    <Provider store={store}>
      <main>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/admin/:ep" element={<Admin />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/about" element={<About />} />
            <Route path="/product/:id" element={<EditProductForm />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/podcasts" element={<Podcasts />} />
          </Routes>
        </Layout>
      </main>
    </Provider>
  )
}

export default App
