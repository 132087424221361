

export const GET_PRODUCTS = `GET_PRODUCTS`
export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT'

export const getProducts = () => {
    return async (dispatch) => {
        const url = 'https://ihatecars.net/php/getProducts.php'

        fetch(url)
            .then((res) => res.json())
            .then((data) =>
                dispatch({
                    type: GET_PRODUCTS,
                    products: data,
                })
            )
            .catch((e) => console.log('Error getting products: ', e.message))
    }
}

export const getSingleProduct = (id) => {
    return async (dispatch) => {
        const url = 'https://ihatecars.net/php/getSingleProduct.php'
        const data = { id }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) =>
                dispatch({
                    type: GET_SINGLE_PRODUCT,
                    singleProduct: data,
                })
            )
            .catch((e) => console.log('Error getting single product: ', e.message))
    }
}

export const updateProduct = (data) => {
    return async (dispatch) => {
        const url = 'https://ihatecars.net/php/updateProduct.php'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            // .then((res) => console.log(res))
            .catch((e) => console.log('Error updating product: ', e.message))
    }
}

export const addProduct = (data) => {
    return async (dispatch) => {
        const url = 'https://ihatecars.net/php/addProduct.php'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            // .then((res) => console.log(res))
            .catch((e) => console.log('Error adding product: ', e.message))
    }
}
