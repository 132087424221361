import { Link, useLocation } from 'react-router-dom'
import '../styles/Header.css'
import menuItems from '../menuItems'
import banner from '../assets/banner_25.png'
import bannerWhite from '../assets/banner_white_25.png'
import { isMobile } from 'react-device-detect'
import { useEffect, useState } from 'react'

const Header = () => {
  const location = useLocation()
  const pathname = location.pathname

  const [mode, setMode] = useState('')

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setMode('dark')
    } else {
      setMode('light')
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const newColorScheme = event.matches ? 'dark' : 'light'
        setMode(newColorScheme)
      })
  }, [])

  const source = mode === 'light' ? banner : bannerWhite

  return (
    <header className="site__header">
      <div className="nav__area__inner">
        {!isMobile && (
          <Link to="/" className="nav__area__logo">
            <img
              className={isMobile ? 'nav__logo_mobile' : 'nav__logo'}
              src={source}
              alt="I HATE CARS"
            />
          </Link>
        )}

        <nav>
          <ul className="menus">
            {menuItems.map(({ url, label }, index) => (
              <li
                className={
                  pathname === url ? 'menu__items_active' : 'menu__items'
                }
                key={index}
              >
                <Link to={url}>{label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
