import { Link } from 'react-router-dom'
import '../styles/EditProductButton.css'

const EditProductButton = ({ product }) => {
    const { id, name, image, price, description } = product

    const deleteProduct = () => {
        if (id) {
            const url = `https://ihatecars.net/php/deleteProduct.php?id=${id}`

            fetch(url).catch(e => console.log(e.message))
        }
    }

    const handleDelete = () => {
        const text = 'Are you sure you want to delete this?'
        if (window.confirm(text) === true) {
            deleteProduct()
        } else {
            console.log('cancelled')
        }
    }

    return (
        <div className='product'>
            <img src={image} alt={name} className='image' />
            <h3>{name}</h3>
            <p className='description'>{description}</p>
            <p className='price'>{price}</p>

            <div className='buttonContainer'>
                <Link to={`/product/${id}`}>
                    <button className='button'>Edit</button>
                </Link>
                <button className='button' onClick={handleDelete}>Delete</button>
            </div>
        </div>
    )
}


export default EditProductButton