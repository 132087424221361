import { Link } from 'react-router-dom'
import '../styles/Footer.css'
import { BsGear } from 'react-icons/bs'
import { isMobile } from 'react-device-detect'
import SocialMediaButtons from './SocialMediaButtons'

const Footer = () => {
  // const handleScroll = () => {
  //   window.scrollTo(0, 0)
  // }

  return (
    <footer>
      <div className="inner">
        <SocialMediaButtons footer={true} />

        <div className="footer__content">
          <Link to="/admin" title="Admin">
            <BsGear color="#6a6767" size="25px" className='footer_gear' />
            <div style={{ width: isMobile ? 0 : 95 }}></div>
          </Link>

          <p title="Go to akaDesignTech.com">
            &copy; {new Date().getFullYear().toString()} by{' '}
            <a
              href="http://akaDesignTech.com"
              rel="noreferrer"
              target="_blank"
              className='footer_link'
            >
              DesignTech
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
