import { Helmet } from 'react-helmet'

const MetaDecorator = (props) => {
    const title = props.title
        ? props.title
        : 'I HATE CARS Podcast'

    const description = props.description
        ? props.description
        : 'Jeff is here to begrudgingly help you answer questions about your car.'

    const noscript = props.noscript
        ? props.noscript
        : "Whether you're looking for information on how to maintain your vehicle or need help troubleshooting an issue with your car, Jeff Rickermann has got you covered. He'll explain things in simple terms so everyone can understand. You need to enable JavaScript to visit this site. Btw, I HATE CARS"

    const keywords = props.keywords
        ? props.keywords
        : "i hate cars, I HATE CARS, ihatecars.net, ihatecars, hatecars, hate cars, car maintenance, car repair, car questions, car troubleshooting, jeff rickermann, jeff rickerman, carx, jeff, podcast, car podcast, car issues, jeff podcast, rickermann, rickermann podcast, broken car, car, cars, car problems, car parts, buying a car, purchase a car, maintain a car, @jeffhatescars, jeffhatescars, jeff hates cars, car maintenance "

    const image = props.image ? props.image : "https://ihatecars.net/grunge_poster_extended.jpg"

    const imageAlt = props.imageAlt ? props.imageAlt : 'I HATE CARS'

    const url = props.url ? props.url : window.location.href

    const siteName = props.siteName ? props.siteName : 'I HATE CARS'

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
                rel="icon"
                type="image/x-icon"
                href="https://ihatecars.net/favicon.ico"
            />
            <noscript>{noscript}</noscript>
            <meta name="keywords" content={keywords} />
            <meta name="author" content="https://akaDesignTech.com" />

            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content={siteName} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="I HATE CARS Podcast" />
            <meta name="twitter:image:alt" content={imageAlt} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:site" content="@jeffhatescars" />
        </Helmet>
    )
}

export default MetaDecorator
