export const generateCustomerHTML = (body) => {
    const currentYear = new Date().getFullYear().toString()
    const startYear = `2023`
    const copyright =
        currentYear === startYear ? startYear : `${startYear}-${currentYear}`

    return `<html style='height:100%;padding:0px;margin:0px;'>
      <head>
          <title>I HATE CARS Message</title>
      </head>

      <body style='height:100%;padding:0px;margin:0px;height:100%;background-color:#eeeeef;'>

      <p style='display:none;'>Thank you for writing to I HATE CARS!</p>

      <center>

      <table cellpadding='0' cellspacing='0'>
      <tr>
      <td style='font-size: 1px; line-height: 1px;' height='20'>&nbsp;</td>
      </tr>
      </table>

      <table style='width:100%;min-width:200px;max-width:650px; border:none; background-color:#ffffff; ' cellpadding='0' cellspacing='0'>

      <tr>
      <td  align='left' style='text-align:left; padding:20px 30px 20px 30px;'>

      <font style='font-size:16px; line-height:23px; color:#494949;' face='Arial, Helvetica, sans-serif, 'Arial', 'Helvetica', 'Sans Serif''>

      <p>

      <a href='https://ihatecars.net' >
      <img border='0' alt='ihatecars.net' src='https://ihatecars.net/grunge_516.png' height='150' />
      </a>

      <h2 style='color:#FF0000;line-height:1;margin-top:30px;'
  >Thank you for contacting me, ${body.name}!</h2>
      <h3><i>This is an automated response, but I will get back to you shortly.</i></h3>

      <p style='margin-bottom:30px;'>Thanks again and have a great day!<br/><i style='line-height:2;'>Jeff</i></p>

      </p>

      </font>
      </td>
      </tr>

      <tr>
      <td  align='left' style='text-align:left; background-color: #f2f2f2; padding:20px 30px 20px 30px;'>

      <font style='font-size:12px;' face='Arial, Helvetica, sans-serif, 'Arial', 'Helvetica', 'Sans Serif''>
      &copy; ${copyright} DesignTech
      </font>

      </td>
      </tr>

      </table>

      <table cellpadding='0' cellspacing='0'>
      <tr>
      <td style='font-size: 1px; line-height: 1px;' height='20'>&nbsp;</td>
      </tr>
      </table>

      </center>
      </body>
  </html>`
}

export const generateJeffHTML = (body) => {
    const currentYear = new Date().getFullYear().toString()
    const startYear = `2023`
    const copyright =
        currentYear === startYear ? startYear : `${startYear}-${currentYear}`

    let objectDate = new Date()
    let day = objectDate.getDate()
    let month = objectDate.getMonth() + 1
    let year = objectDate.getFullYear()
    let formatDate = `${month}/${day}/${year}`
    let guestType = body.guestType === 'question' ||
        body.guestType === ''
        ? `<h2>${body.name} has a question for you.</h2>`
        : body.guestType === 'advertise'
            ? `<h2>${body.name} would like to advertise on the show.</h2>`
            : `<h2>${body.name} would like to be a guest on your show.</h2>`

    return `<html style='height:100%;padding:0px;margin:0px;'>
    <head>
        <title>
            I HATE CARS Message
        </title>
    </head>

    <body style='height:100%;padding:0px;margin:0px;height:100%;background-color:#eeeeef;'>

    <p style='display:none;'>A message from ${body.name}!</p>

    <center>

    <table cellpadding='0' cellspacing='0'>
    <tr>
    <td style='font-size: 1px; line-height: 1px;' height='20'>&nbsp;</td>
    </tr>
    </table>

    <table style='width:100%;min-width:200px;max-width:650px; border:none; background-color:#ffffff; ' cellpadding='0' cellspacing='0'>

    <tr>
    <td  align='left' style='text-align:left; padding:20px 30px 20px 30px;'>

    <font style='font-size:16px; line-height:23px; color:#494949;' face='Arial, Helvetica, sans-serif, 'Arial', 'Helvetica', 'Sans Serif''>

    <p>

    <a href='https://ihatecars.net' >
    <img border='0' alt='ihatecars.net' src='https://ihatecars.net/grunge_516.png' height='150' />
    </a>

         <h2 style='color:#FF0000;line-height:1;margin-top:30px;'
    >NEW MESSAGE FROM ${body.name}</h2>
        ${guestType}
        <p><b>Name: </b> ${body.name}</p>
        <p><b>Email: </b> <a href="mailto:${body.email}?subject=Your message sent to iHATEcars.net on ${formatDate}">${body.email}</a></p>
        <p><b>Phone: </b> ${body.phone}</p>
        <p><b>Call on: </b> ${body.day} ${body.time}</p>
        <p><b>Car: </b> ${body.car}</p>
        <p><b>Message: </b> ${body.message}</p>
    </p>

        </font>
        </td>
        </tr>

        <tr>
        <td  align='left' style='text-align:left; background-color: #f2f2f2; padding:20px 30px 20px 30px;'>

        <font style='font-size:12px;' face='Arial, Helvetica, sans-serif, 'Arial', 'Helvetica', 'Sans Serif''>
        &copy; ${copyright} DesignTech
        </font>

        </td>
        </tr>

        </table>

        <table cellpadding='0' cellspacing='0'>
        <tr>
        <td style='font-size: 1px; line-height: 1px;' height='20'>&nbsp;</td>
        </tr>
        </table>

        </center>
    </body>
    </html>`
}