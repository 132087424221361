import Header from './Header'
import Footer from './Footer'
import '../styles/Layout.css'
import { ToastComp } from './Toast'

const Layout = ({ children }) => {
    return (
        <div className="container">
            <div className="inner__wrapper">
                <ToastComp />
                <Header />
                <main className="content">{children}</main>
            </div>
            <Footer />
        </div>
    )
}

export default Layout