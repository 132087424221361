import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import Carousel from '../components/Carousel'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Home.css'
import { addApos } from '../utils/regexInput'
import { PageLoader } from '../components/Loader'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://ihatecars.net/php/getPages.php'

// let count = 0
const Home = (props) => {
  // console.log(count++)

  const { data, error, isLoading } = useSWR(url, fetcher)

  const [images, setImages] = useState([])
  const [pages, setPages] = useState([])
  const [text, setText] = useState('')

  useEffect(() => {
    data && setPages(data[0])
  }, [data])

  useEffect(() => {
    if (pages && pages.homeImages) {
      let temp = pages.homeImages.split(',')
      temp = temp.filter((i) => i.includes('http'))
      setImages(temp)
    }
    if (pages && pages.home) {
      setText(pages.home)
    }
  }, [pages])

  if (error) return <div><h1>Error.</h1><p>{error}</p></div>
  if (isLoading) return <PageLoader />

  return pages && (
    <div className="home">
      <MetaDecorator />

      <img
        src='https://ihatecars.net/grunge_1080.png'
        style={{
          maxWidth: '100%',
          maxHeight: 1080,
          marginBottom: 20,
        }}
        alt="i hate cars"
      />

      {text.includes('<')
        ? (
          <div
            dangerouslySetInnerHTML={{
              __html: addApos(text),
            }}
          ></div>
        ) : (
          <p>
            {text.length
              ? addApos(text)
              : 'Welcome to iHATEcars.net!'}
          </p>
        )
      }

      <p>
        <i>
          Ask to be on the show or send a question / comment about your fucking
          car to Jeff on the{' '}
          <Link
            to="/contact"
            style={{
              color: 'rgb(244, 0, 0)',
              fontWeight: 600,
              display: 'inline',
              textDecorationLine: 'underline',
              fontSize: 20,
            }}
          >
            contact page
          </Link>
        </i>
      </p>

      <Carousel images={images} />

    </div >
  )
}

export default Home
