import { Link } from 'react-router-dom'
import '../styles/forms.css'
import { useState } from 'react'
import validator from 'validator'
import {
  generateCustomerHTML,
  generateJeffHTML,
} from '../utils/generateEmailHTML'
import MetaDecorator from '../components/MetaDecorator'
import { SectionLoader } from '../components/Loader'

const sendContactEmailsUrl = 'https://ihatecars.net/php/sendContactEmails.php'
// let count = 0

const Contact = () => {
  const [guestType, setGuestType] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [day, setDay] = useState('')
  const [time, setTime] = useState('')
  const [car, setCar] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(null)

  const clearFields = () => {
    setName('')
    setEmail('')
    setPhone('')
    setDay('')
    setTime('')
    setCar('')
    setMessage('')
    setError('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(false)

    if (validator.isEmail(email) && name !== '') {
      let info = {
        guestType,
        name: validator.escape(name),
        email,
        phone: validator.escape(phone),
        day,
        time,
        car: validator.escape(car),
        message: validator.escape(message),
      }

      const data = {
        customerHTML: generateCustomerHTML(info),
        jeffHTML: generateJeffHTML(info),
        name: validator.escape(name),
        email,
      }

      fetch(sendContactEmailsUrl, {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((res) => {
          console.log('Response received: ', res.ok)
          if (res.ok) {
            setSubmitted(true)
            clearFields()
          }
        })
        .catch((e) => console.log('ERROR: ', e.message))
    } else {
      setError('You must provide a name and valid email address')
    }
  }

  const handleRadioButtons = (e) => {
    setGuestType(e.target.value)
    setSubmitted(null)
    setError('')
  }

  // console.log(count++);

  return (
    <>
      <MetaDecorator
        title="I HATE CARS | Contact Jeff"
        description="Ask to be on the show or send a question / comment about your fucking car to Jeff"
        url="https://ihatecars.net/contact"
      />

      <h1>Contact.</h1>

      <div className="container">
        <form className="main">
          <input
            type="radio"
            id="question"
            name="guestType"
            value="question"
            onChange={handleRadioButtons}
            checked={guestType === '' || guestType === 'question'}
          />

          <label htmlFor="question">I have a question or comment for{' '}
            <Link
              to={'/about'}
              className="link"
              style={{
                color: 'rgb(244, 0, 0)',
                fontWeight: 600,
                display: 'inline',
                textDecorationLine: 'underline',
                fontSize: 16,
              }}
            >
              Jeff
            </Link></label>

          <br />

          <input
            type="radio"
            id="guest"
            name="guestType"
            value="guest"
            onChange={handleRadioButtons}
          />

          <label htmlFor="guest">I would like to be a guest on the show</label>
          <br />

          <input
            type="radio"
            id="advertise"
            name="guestType"
            value="advertise"
            onChange={handleRadioButtons}
          />

          <label htmlFor="advertise">I would like to advertise on the show</label>

          <br /><br />

          <div className="inputGroup">
            <label title="Required" htmlFor="name">Name *</label>
            <input
              type="text"
              name="name"
              value={name}
              className="inputField"
              required
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setName(e.target.value)
              }}
            />
          </div>

          <div className="inputGroup">
            <label title="Required" htmlFor="email">Email *</label>
            <input
              type="email"
              name="email"
              value={email}
              className="inputField"
              required
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setEmail(e.target.value)
              }}
            />
          </div>

          <div className="inputGroup">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              name="phone"
              value={phone}
              className="inputField"
              maxLength={20}
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setPhone(e.target.value)
              }}
            />
          </div>

          <div className="inputGroup">
            <label htmlFor="day">
              If you would like Jeff to call you, when are you are available?
            </label>
            <select
              name="day"
              id="day"
              value={day}
              className="select"
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setDay(e.target.value)
              }}
            >
              <option value="none">--</option>
              <option value="sunday">Sunday</option>
              <option value="monday">Monday</option>
            </select>
          </div>

          <div className="inputGroup">
            <label htmlFor="time">
              Time you are available for a phone call (Central Standard Time)
            </label>

            <select
              name="time"
              id="time"
              value={time}
              className="select"
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setTime(e.target.value)
              }}
            >
              <option value="none">--</option>
              <option value="morning (8a-12p)">Morning (8am - 12pm CST)</option>
              <option value="afternoon (12p-5p)">
                Afternoon (12pm - 5pm CST)
              </option>
              <option value="evening (5p-9p)">Evening (5pm - 9pm CST)</option>
            </select>
          </div>

          {(guestType === 'question' || guestType === '') &&
            <div className="inputGroup">
              <label htmlFor="car">Make, Model, and Year of Car</label>
              <textarea
                maxLength="500"
                name="car"
                value={car}
                className="smtextarea"
                onChange={(e) => {
                  setError('')
                  setSubmitted(null)
                  setCar(e.target.value)
                }}
              />
            </div>}

          <div className="inputGroup">
            <label htmlFor="message">
              {(guestType === 'question' || guestType === '')
                ? 'Question or Comment'
                : guestType === 'advertise'
                  ? 'Comments'
                  : 'Why do you want to be a guest on the show?'
              }
            </label>
            <textarea
              maxLength="10000"
              name="message"
              value={message}
              className="textarea"
              onChange={(e) => {
                setError('')
                setSubmitted(null)
                setMessage(e.target.value)
              }}
            />
          </div>

          <input
            type="submit"
            value="Submit"
            className="submit"
            onClick={(e) => {
              handleSubmit(e)
            }}
          />

          <p className="error">{error}</p>
          <h3 className="success">
            {submitted === true
              ? 'Success! Your message was sent.'
              : submitted === null
                ? ''
                : <SectionLoader />}
          </h3>
        </form>
      </div>
    </>
  )
}

export default Contact
