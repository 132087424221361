import DonateButton from '../components/DonateButton'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Shop.css'
import BuyButton from '../components/BuyButton'
import { sort } from 'fast-sort'
import useSWR from 'swr'
import { PageLoader } from '../components/Loader'

// let count = 0
const fetcher = (url) => fetch(url).then((res) => res.json())

const Shop = () => {
    const url = 'https://ihatecars.net/php/getProducts.php'

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error)
        return (
            <div>
                <h1>Error.</h1>
                <p>Please refresh the page.</p>
            </div>
        )
    if (isLoading) return <PageLoader />

    const items = sort(data).asc(u => u.category)

    return data && (
        <div className='shop'>
            <MetaDecorator
                title="I HATE CARS | Shop. Donate."
                description="Shop for cool swag!"
                url="https://ihatecars.net/shop"
            />

            <h1>Donate. Shop.</h1>

            <h2 className='message'>Donations of any amount are greatly appreciated.</h2>

            <DonateButton />


            <h2 className='onDemand'>Buy some swag to spread the message.</h2>

            <p className='onDemand'>These products are made on demand. This helps reduce overproduction, but it costs a little more. Thank you for making thoughtful purchasing decisions!</p>


            <div className="items">
                {items &&
                    items.map((prod) => prod.name &&
                        <BuyButton product={prod} key={prod.name} />
                    )
                }
            </div>
        </div>
    )
}

export default Shop