import { combineReducers } from 'redux'

// import podcastsReducer from '../../../unused/podcasts(reducer)'
import pagesReducer from './pages'
import productsReducer from './products'

const rootReducer = combineReducers({
    // podcasts: podcastsReducer,
    pages: pagesReducer,
    products: productsReducer,
})

export default rootReducer