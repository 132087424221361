import '../styles/buyButton.css'

const BuyButton = ({ product }) => {
    const { link, name, image, price, description } = product

    const showCheckoutWindow = (e) => {
        e.preventDefault()

        const url = link
        const title = name

        // Some platforms embed in an iframe, so we want to top window to calculate sizes correctly
        const topWindow = window.top ? window.top : window

        // Fixes dual-screen position                                Most browsers          Firefox
        const dualScreenLeft = topWindow.screenLeft !== undefined ? topWindow.screenLeft : topWindow.screenX
        const dualScreenTop = topWindow.screenTop !== undefined ? topWindow.screenTop : topWindow.screenY

        const width = topWindow.innerWidth ? topWindow.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
        const height = topWindow.innerHeight ? topWindow.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height

        const h = height * .75
        const w = 500

        const systemZoom = width / topWindow.screen.availWidth
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`)

        if (window.focus) newWindow.focus()
    }

    return (
        <div className='buy'>
            <img src={image} alt={name} className='image' />
            <h3>{name}</h3>
            <p className='description'>{description}</p>
            <div className='price_container'>
                <p className='price'>{price}</p>
                <p className='free'>FREE SHIPPING</p>
            </div>
            <p className='us_only'>Unites States only</p>


            <div className='donate'>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={link}
                    className='button'
                    onClick={showCheckoutWindow}
                >BUY NOW</a>
            </div>
        </div>
    )
}

export default BuyButton