export const GET_PAGES = 'GET_PAGES'

export const getPages = () => {
  return async (dispatch) => {
    const url = 'https://ihatecars.net/php/getPages.php'

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: GET_PAGES,
          pages: data,
        })
      })
      .catch((e) => console.log('Error getting Pages data: ', e.message))
  }
}

export const savePagesToDb = (data) => {
  // console.log(data);
  return async (dispatch) => {
    const url = 'https://ihatecars.net/php/setPages.php'

    if (data) {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      }).catch((e) => console.log('Error setting Pages: ', e.message))
    }
  }
}
