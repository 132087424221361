import { useState } from 'react'
import '../../styles/forms.css'

const AddPodcast = () => {
    const [spotifyUrl, setSpotifyUrl] = useState('')
    const [title, setTitle] = useState('')

    const [resultMessage, setResultMessage] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const dbUrl = `https://ihatecars.net/php/addPodcast.php?url=${spotifyUrl}&title=${title}`

        fetch(dbUrl)
            .then(() => {
                setResultMessage('Success')
                setSpotifyUrl('')
                setTitle('')
            })
            .catch((e) => setResultMessage('Error'))
    }

    return (
        <div style={styles.main}>
            <form className="main" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    value={title}
                    className="inputField"
                    style={styles.input}
                    placeholder='Title'
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                />

                <input
                    type="text"
                    name="spotifyUrl"
                    value={spotifyUrl}
                    className="inputField"
                    style={styles.input}
                    placeholder='Spotify URL'
                    onChange={(e) => {
                        setSpotifyUrl(e.target.value)
                    }}
                />

                <div className="button_container">
                    <input
                        type="submit"
                        value="Submit"
                        className="submit"
                        onClick={handleSubmit}
                    />
                    <h3 style={resultMessage === 'Error' ? styles.error : styles.success}>{resultMessage}</h3>
                </div>
            </form>
        </div>
    )
}

const styles = {
    main: {
        width: '100%',
    },
    input: {
        width: '100%',
        marginTop: 20,
    },
    error: {
        color: 'red',
        paddingRight: 1,
    },
    success: {
        color: 'limegreen',
        paddingRight: 1,
    },
}

export default AddPodcast