import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useNavigate, useParams } from "react-router-dom"
import LoginForm from '../components/admin/LoginForm'
import { PageLoader } from '../components/Loader'
import Products from '../components/admin/Products'
import '../styles/Admin.css'
import EditHome from '../components/admin/EditHome'
import EditAbout from '../components/admin/EditAbout'
import AddPodcast from '../components/admin/AddPodcast'
import { isMobile } from 'react-device-detect'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://ihatecars.net/php/getPages.php'
const secretPassword = process.env.REACT_APP_PASSWORD

const Admin = () => {
  let navigate = useNavigate()
  const { ep } = useParams()

  const [page, setPage] = useState(ep ? 'products' : '')
  const [password, setPassword] = useState('')
  const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem('password') === secretPassword)

  useEffect(() => {
    sessionStorage.setItem('password', password)
    setLoggedIn(sessionStorage.getItem('password') === secretPassword)
  }, [password])

  const { data, error, isLoading } = useSWR(url, fetcher)
  if (error) return <div><h1>Error.</h1><p>{error}</p></div>
  if (isLoading) return <PageLoader />

  const handleLogout = () => {
    sessionStorage.removeItem('password')
    setPage('')
    setLoggedIn(false)
    setPassword('')
    navigate('/')
  }

  const handleEditHome = () => {
    setPage('home')
  }

  const handleEditAbout = () => {
    setPage('about')
  }

  const handleEditProducts = () => {
    setPage('products')
  }

  const handleAddPodcast = () => {
    setPage('addPodcast')
  }

  return (
    <div className='admin'>

      <h1>Admin.</h1>

      {loggedIn &&
        <div>
          <button
            className='logout'
            onClick={handleLogout}
          >Log out</button>

          <div className="admin_buttons" style={styles.buttonContainer}>

            <button
              onClick={handleEditHome}
              disabled={page === 'home'}
            >Edit Home</button>

            <button
              onClick={handleEditAbout}
              disabled={page === 'about'}
            >Edit About</button>

            <button
              onClick={handleEditProducts}
              disabled={page === 'products'}
            >Edit Products</button>

            <button
              onClick={handleAddPodcast}
              disabled={page === 'addPodcast'}
            >Add Podcast</button>
          </div>
        </div>
      }

      {loggedIn
        ? page === 'home'
          ? <EditHome setPage={setPage} data={data} />
          : page === 'about'
            ? <EditAbout setPage={setPage} data={data} />
            : page === 'products'
              ? <Products />
              : page === 'addPodcast'
                ? <AddPodcast />
                : <p className='default'>Edit the homepage, the about page, or the products.</p>
        : <LoginForm password={password} setPassword={setPassword} />
      }
    </div>
  )
}

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
  }
}

export default Admin
