import { useState } from 'react'
import '../../styles/forms.css'

const LoginForm = (props) => {
    const { password, setPassword } = props

    const [type, setType] = useState('password')

    return (
        <div className="inputGroup">
            <label htmlFor="name">Password</label>
            <input
                type={type}
                onMouseOver={() => setType('text')}
                onMouseLeave={() => setType('password')}
                name="name"
                value={password}
                className="inputField"
                required
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
            />
        </div>
    )
}

export default LoginForm