import useSWR from 'swr'
import { sort } from 'fast-sort'
import { Link } from 'react-router-dom'
import { PageLoader } from '../Loader'
import EditProductButton from '../EditProductButton'
import '../../styles/Products.css'

const fetcher = (url) => fetch(url).then((res) => res.json())

const Products = () => {

    const url = 'https://ihatecars.net/php/getProducts.php'

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error)
        return (
            <div>
                <h1>Error.</h1>
                <p>Please refresh the page.</p>
            </div>
        )

    if (isLoading) return <PageLoader />

    const items = sort(data).asc(u => u.category)

    return (
        <div className='products'>

            <Link to={`/product/null`}>
                <button>Add Product</button>
            </Link>

            <div className="items">
                {items &&
                    items.map((prod) => prod.name &&
                        <EditProductButton
                            key={prod.id}
                            product={prod}
                        />
                    )
                }
            </div>
        </div>
    )
}
export default Products