import { TiDelete } from 'react-icons/ti'
import { handleImageDelete } from '../../utils/handleImageDelete'

const DeleteButton = (props) => {
  const { file, imageArray, setImageArray } = props

  const handleDeleteFile = async () => {
    handleImageDelete(file)
    const newArray = imageArray.replace(file, '').replace(/,,/g, ',')
    setImageArray(newArray)
  }

  return (
    <TiDelete
      color="red"
      size={30}
      style={styles.deleteButton}
      onClick={handleDeleteFile}
    />
  )
}

const styles = {
  deleteButton: {
    transform: 'translateY(35px)',
    cursor: 'pointer',
  },
}

export default DeleteButton
