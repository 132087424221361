import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { shuffle } from '../utils/shuffle'

function Carousel(props) {
  const { images } = props

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,
  }

  const imgStyle = {
    width: '100%',
  }

  return (
    <Slider {...settings}>
      {images &&
        shuffle(images).map((img) => (
          <div key={img}>
            <img src={img} style={imgStyle} alt='car' />
          </div>
        ))}
    </Slider>
  )
}

export default Carousel
