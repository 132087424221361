import axios from 'axios'

export const handleImageDelete = async (url) => {
    if (!url || url === '') return

    const deleteUrl =
        'https://ihatecars.net/podcastImages/delete.php'

    const pop = url.split('/podcastImages/').pop()
    const data = { file: pop }

    await axios.post(deleteUrl, data).catch((e) => console.log(e.message))
}
